import { vapi } from './config';

// Start a Vapi call with inline configuration
export const startCall = async (config) => {
  try {
    const { variableValues, serverUrl, ...assistantConfig } = config;
    
    console.log('Starting call with:', {
      assistantConfig,
      options: { variableValues, serverUrl }
    });
    
    try {
      await vapi.start(assistantConfig, { variableValues, serverUrl });
      return true;
    } catch (vapiError) {
      // Log the actual error response from the API
      if (vapiError.response) {
        const errorData = await vapiError.response.json();
        console.error('API Error Details:', errorData);
      }
      console.error("Vapi API Error:", {
        error: vapiError,
        message: vapiError.message,
        details: vapiError.details || 'No details available',
        response: vapiError.response
      });
      throw vapiError;
    }
  } catch (error) {
    console.error('Error starting Vapi call:', error);
    throw error;
  }
};

// Stop a Vapi call
export const stopCall = async () => {
  try {
    await vapi.stop();
    return true;
  } catch (error) {
    console.error('Error stopping Vapi call:', error);
    throw error;
  }
};

export { vapi };
