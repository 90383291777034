/* eslint no-console:0 */

// Rails functionality
import Rails from "@rails/ujs";
import "@hotwired/turbo-rails";

// Make accessible for Electron and Mobile adapters
window.Rails = Rails;

require("@rails/activestorage").start();
import "@rails/actiontext";

// ActionCable Channels
import "./channels";

// Stimulus controllers
import "./controllers";

// Jumpstart Pro & other Functionality
import "./src/**/*";
require("local-time").start();

require("jquery");
import jQuery from "jquery";
window.$ = window.jQuery = jQuery;
require("@nathanvda/cocoon");

// Stimulus application setup
import { Application } from "@hotwired/stimulus"
import SearchController from "./controllers/search_controller"

const application = Application.start()
application.register("search", SearchController)

// Import Vapi setup
import { setupVapiEvents } from "./lib/vapi/events";
import { setupStudyButtons } from "./features/studies/study_agent";
import { initializeInterviewSetup } from "./features/interviews/interview_agent";

// Function to add event listeners to the buttons
function addEventListeners() {
  // Setup Vapi events
  setupVapiEvents();

  // Setup study or interview buttons based on page type
  if (document.querySelector('[data-interview-id]')) {
    initializeInterviewSetup();
  } else {
    setupStudyButtons();
  }

  // Keep existing flash message handling
  const flashElement = document.getElementById("flash");
  const closeIcon = document.getElementById("close-flash");

  if (flashElement) {
    setTimeout(() => {
      flashElement.classList.add("flash-slide-out");
    }, 3000);

    if (closeIcon) {
      closeIcon.addEventListener("click", () => {
        flashElement.classList.add("flash-slide-out");
      });
    }
  }
}

// Add event listeners when Turbo loads a page
document.addEventListener("turbo:load", addEventListeners);

// Start Rails UJS
Rails.start();

