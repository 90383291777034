import { startCall, stopCall } from '../../lib/vapi/index';

// Get inline assistant configuration
export const createStudyAgent = (firstName, studySlug) => ({
  // Assistant configuration
  name: "Study Agent",
  transcriber: {
    provider: "deepgram",
    model: "nova-2",
    language: "en-US"
  },
  voice: {
    provider: "playht",
    voiceId: "jennifer"
  },
  model: {
    provider: "openai",
    model: "gpt-4",
    messages: [
      {
        role: "system",
        content: `[Identity]
You are an expert user experience research AI assistant, your goal is to plan customer interviews by creating a discussion guide which will be used to outline the structure of customer interviews that you will be conducting.

[Style]
- Be friendly, informative and comprehensive
- Maintain a professional and polite tone
- Be concise

[Response Guideline]
- IMPORTANT: Only ask one question at a time then wait for a response
- Pause after questions and allow the user talk
- Ask follow up questions and dig deeper to extract insights
- Ask open ended questions about  then dig deeper
- Use terminology familiar to user researchers, product designers and product managers, marketing managers and customer service managers

[Task]
1. Introduce yourself and explain what your purpose is 
2. Establish the primary objectives of the user research
3. Identify the target audience is and what are their characteristics
4. Learn about the desired outcomes of the research
5. Gather details on the context of the customer interviews research
6. Understand any customer interview constraints or limitations 
7. Ask follow up questions based on the discussion that might help create an customer interview guide

The participant's first name is ${firstName}.`
      }
    ]
  },
  firstMessage: `Hello ${firstName}`,
  // Call-specific configuration
  variableValues: {
    first_name: firstName,
    study_id: studySlug
  },
  serverUrl: `${window.location.origin}/vapi/webhook`
});

// Setup Study Buttons
export const setupStudyButtons = () => {
  const startButton = document.getElementById("start-call");
  const stopButton = document.getElementById("stop-call");

  if (startButton) {
    const firstName = startButton.dataset.firstName;
    const studySlug = startButton.dataset.studySlug;

    startButton.addEventListener("click", async () => {
      try {
        const assistant = createStudyAgent(firstName, studySlug);
        await startCall(assistant);
        startButton.style.display = "none";
        if (stopButton) stopButton.style.display = "";
      } catch (error) {
        console.error("Failed to start study:", error);
      }
    });
  }

  if (stopButton) {
    stopButton.addEventListener("click", async () => {
      try {
        await stopCall();
        stopButton.style.display = "none";
        if (startButton) startButton.style.display = "";
      } catch (error) {
        console.error("Failed to stop study:", error);
      }
    });
  }
}; 