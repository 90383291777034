import { vapi } from '../../lib/vapi/config';
import { startCall, stopCall } from '../../lib/vapi/index';

// Interview Assistant Configuration
export const INTERVIEW_ASSISTANT_ID = "your-interview-assistant-id";

// Global variables to track test completion status
let webcamTested = false;
let micTested = false;
let speakerTested = false;

// Function to request permissions and setup devices
async function setupDevices() {
  try {
    // Request both video and audio for recording
    const combinedStream = await navigator.mediaDevices.getUserMedia({ 
      video: true,
      audio: true
    });
    
    // Setup webcam - but mute the playback
    const webcamContainer = document.getElementById('webcam-container');
    const webcamVideo = document.getElementById('webcam-video');

    if (webcamContainer && webcamVideo) {
      webcamVideo.srcObject = combinedStream;
      webcamVideo.style.transform = 'scaleX(-1)'; // Mirror mode
      webcamVideo.muted = true; // Mute the video element to prevent feedback
      webcamContainer.style.display = 'block';
      webcamTested = true;
    }

    // Setup audio visualizer
    const micContainer = document.getElementById('mic-container');
    const audioVisualizer = document.getElementById('audio-visualizer');

    if (micContainer && audioVisualizer) {
      const ctx = audioVisualizer.getContext('2d');
      const audioContext = new (window.AudioContext || window.webkitAudioContext)();
      const analyser = audioContext.createAnalyser();
      const microphone = audioContext.createMediaStreamSource(combinedStream);
      microphone.connect(analyser);
      
      analyser.fftSize = 256;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      function draw() {
        requestAnimationFrame(draw);
        analyser.getByteFrequencyData(dataArray);
        
        ctx.fillStyle = 'rgb(255, 255, 255)';
        ctx.fillRect(0, 0, audioVisualizer.width, audioVisualizer.height);
        
        const barWidth = (audioVisualizer.width / bufferLength) * 2.5;
        let x = 0;
        for (let i = 0; i < bufferLength; i++) {
          const barHeight = dataArray[i] / 2;
          ctx.fillStyle = `rgb(${barHeight + 100}, 50, 50)`;
          ctx.fillRect(x, audioVisualizer.height - barHeight, barWidth, barHeight);
          x += barWidth + 1;
        }
      }

      draw();
      micContainer.style.display = 'block';
      micTested = true;

      // Store the stream for later cleanup
      window.mediaStream = combinedStream;
      window.audioContext = audioContext;
    }

    // Play test sound
    const audio = new Audio('https://customerinterviewsai-public.s3.eu-west-2.amazonaws.com/tell-me-a-joke.mp3');
    await audio.play();
    speakerTested = true;

  } catch (err) {
    console.error('Error setting up devices:', err);
    alert('Unable to access camera and/or microphone. Please check your permissions.');
  }
}

// Function to stop preview of microphone
function stopAudioPreview() {
  // Stop audio visualizer and microphone
  const audioVisualizer = document.getElementById('audio-visualizer');
  if (audioVisualizer) {
    const ctx = audioVisualizer.getContext('2d');
    ctx.clearRect(0, 0, audioVisualizer.width, audioVisualizer.height);
  }
  document.getElementById('mic-container').style.display = 'none';

  // Stop any ongoing audio context
  if (window.audioContext) {
    window.audioContext.close().then(() => {
      console.log('AudioContext closed');
      window.audioContext = null;
    });
  }
}

// Create study agent configuration
const createInterviewAgent = (firstName, studySlug, interviewSlug) => ({
  name: "Interview Agent",
  transcriber: {
    provider: "deepgram",
    model: "nova-2",
    language: "en-US"
  },
  voice: {
    provider: "playht",
    voiceId: "jennifer"
  },
  model: {
    provider: "openai",
    model: "gpt-4",
    messages: [
      {
        role: "system",
        content: `[Identity]
You are an expert user experience research AI assistant, your goal is to conduct customer interviews based on the discussion guide that has been created.

[Style]
- Be friendly, informative and comprehensive
- Maintain a professional and polite tone
- Be concise

[Response Guideline]
- IMPORTANT: Only ask one question at a time then wait for a response
- Pause after questions and allow the user talk
- Ask follow up questions and dig deeper to extract insights
- Ask open ended questions about  then dig deeper
- Use terminology familiar to user researchers, product designers and product managers, marketing managers and customer service managers

[Task]
1. Introduce yourself and explain what your purpose is 
2. Follow the discussion guide and ask questions in order
3. Ask follow up questions based on the responses
4. Thank the participant for their time at the end

The participant's first name is ${firstName}.`
      }
    ]
  },
  firstMessage: `Hello ${firstName}`,
  // Call-specific configuration
  variableValues: {
    first_name: firstName,
    study_id: studySlug,
    interview_id: interviewSlug
  },
  serverUrl: `${window.location.origin}/vapi/webhook`
});

// Function to initialize the interview setup
export function initializeInterviewSetup() {
  const startButton = document.getElementById('start-call');
  const stopButton = document.getElementById('stop-call');
  const confirmButton = document.getElementById('confirm-tests');
  const testInstructions = document.getElementById('test-instructions');
  const loadingState = document.getElementById('loading-state');

  // Add event listener for when the AI starts speaking
  vapi.on("speech-start", () => {
    if (loadingState) {
      loadingState.style.display = 'none';
    }
  });

  if (startButton) {
    startButton.addEventListener('click', async () => {
      // Start all tests with combined permissions
      await setupDevices();

      // Show test instructions and confirm button
      if (testInstructions) {
        testInstructions.style.display = 'block';
        testInstructions.innerHTML = `
          <div class="my-4 text-left">
            <p class="mb-2">Please confirm the following:</p>
            <ul class="list-disc list-inside mb-4">
              <li>You can see yourself in the video above</li>
              <li>You heard a voice saying "Tell me a joke"</li>
              <li>You can see the red bars moving when you speak</li>
            </ul>
          </div>
        `;
      }
      
      if (confirmButton) {
        confirmButton.style.display = 'block';
      }
      
      startButton.style.display = 'none';
    });
  }

  if (confirmButton) {
    confirmButton.addEventListener('click', async () => {
      const firstName = startButton.dataset.firstName;
      const studySlug = startButton.dataset.studySlug;
      const interviewSlug = startButton.dataset.interviewSlug;

      try {
        // Show loading state
        if (loadingState) {
          loadingState.style.display = 'block';
        }
        confirmButton.style.display = 'none';
        if (testInstructions) testInstructions.style.display = 'none';

        const assistant = createInterviewAgent(firstName, studySlug, interviewSlug);
        await startCall(assistant);
        
        if (stopButton) stopButton.style.display = 'block';
        // Only stop the audio preview, keep webcam visible
        stopAudioPreview();
      } catch (error) {
        console.error("Failed to start interview:", error);
        // Show error state and reset UI
        if (loadingState) {
          loadingState.style.display = 'none';
        }
        if (confirmButton) {
          confirmButton.style.display = 'block';
        }
        alert('Failed to start the interview. Please try again.');
      }
    });
  }

  if (stopButton) {
    stopButton.style.display = 'none';
    stopButton.addEventListener('click', async () => {
      try {
        await stopCall();
        
        // Hide all interview-related elements
        stopButton.style.display = 'none';
        if (startButton) startButton.style.display = 'none';
        if (loadingState) loadingState.style.display = 'none';
        
        // Stop media streams
        if (window.mediaStream) {
          window.mediaStream.getTracks().forEach(track => track.stop());
          window.mediaStream = null;
        }
        document.getElementById('webcam-container').style.display = 'none';
        
        // Show completion message
        const completionMessage = document.getElementById('completion-message');
        if (completionMessage) {
          completionMessage.style.display = 'block';
        }

        // Hide the rest of the interview content
        const interviewContent = document.getElementById('interview-content');
        if (interviewContent) {
          Array.from(interviewContent.children).forEach(child => {
            if (child.id !== 'completion-message') {
              child.style.display = 'none';
            }
          });
        }

      } catch (error) {
        console.error("Failed to stop interview:", error);
      }
    });
  }
}
